<template>
  <div class="importm">
    <div class="breadcrumb-m">
      <div class="left">
        <Breadcrumb>
          <BreadcrumbItem to="/">主控台</BreadcrumbItem>
          <BreadcrumbItem>导入管理</BreadcrumbItem>
          <BreadcrumbItem>业绩列表</BreadcrumbItem>
        </Breadcrumb>
      </div>
    </div>
    <div class="ser-m">
      <Form ref="formInline" inline :label-width="70">
        <FormItem label="处理状态" v-if="handle_status.length > 0">
          <Select style="width: 120px" v-model="serData.handle_status" @on-change="ser">
            <Option v-for="(item,index) in handle_status" :value="index" :key="index">{{ item.label }}</Option>
          </Select>
        </FormItem>
        <FormItem label="平台" :label-width="43">
          <Select style="width: 120px" v-model="serData.platform_type" @on-change="ser">
            <Option :value="0">全部</Option>
            <Option :value="k" v-for="(p,k) in cfg.platform" :key="k">{{p}}</Option>
          </Select>
        </FormItem>
        <FormItem label="结算方式">
          <Select style="width: 120px" v-model="serData.settlement_type" @on-change="ser">
            <Option :value="0">全部</Option>
            <Option :value="1">仅结算</Option>
            <Option :value="2">结算并计入业绩</Option>
          </Select>
        </FormItem>
        <FormItem label="上传时间">
          <DatePicker type="daterange" placeholder="导入时间" v-model="serData.create_at" style="width: 200px" @on-ok="ser" ></DatePicker>
        </FormItem>
        <FormItem :label-width="0">
          <Button type="primary" @click="ser" @keyup.13="ser"><Icon type="ios-search" size="16" />搜索</Button>
        </FormItem>
      </Form>
    </div>
    <Table size="small" :columns="columns" :data="data" :loading="loading">
      <template slot="excel_name" slot-scope="item">
        <a :href="ApiUrl+'/adm/download_file.html?file='+item.row.file_path+'&name='+item.row.excel_name" target="_blank">{{item.row.excel_name||''}}</a>
      </template>
      <template slot-scope="item" slot="platform_type">
        {{cfg.platform[item.row.platform_type]||''}}
      </template>
      <template slot-scope="item" slot="settlement_type">
        <div :style="'color:'+(item.row.settlement_type==1?'#42b983':'#cc3300')">{{item.row.settlement_type==1?'不计入业绩':'计入业绩'}}</div>
      </template>
      <template slot-scope="item" slot="totle_money">
        <div>总共：{{item.row.totle_money||''}}</div>
        <div>成功：{{item.row.succ_money||''}}</div>
        <div>失败：{{item.row.err_money||''}}</div>
      </template>
      <template slot-scope="item" slot="handle_status">
        <span :style="'color:'+ handle_status[item.row.handle_status].color ">{{handle_status[item.row.handle_status].label}}</span>
      </template>
      <template slot-scope="item" slot="success_line">
        <div>成功：{{item.row.success_line}}</div>
        <div>失败：{{item.row.error_line}}</div>
        <Button v-if="item.row.error_report_file && item.row.error_report_file != ''" size="small" type="warning" @click="errorReport(item.row.error_report_file)">验证失败记录</Button>
        <Button v-else-if="item.row.error_line > 0" size="small" ghost type="warning" disabled>失败记录生成中</Button>
      </template>
      <template slot-scope="item" slot="settle_success_line">
        <div>成功：{{item.row.settle_success_line}}</div>
        <div>失败：{{item.row.settle_error_line}}</div>
        <Button v-if="item.row.error_settle_report_file && item.row.error_settle_report_file != ''" size="small" type="error" @click="errorReport(item.row.error_settle_report_file)">结算失败记录</Button>
        <Button v-else-if="item.row.settle_error_line > 0" size="small" ghost type="error" disabled>失败记录生成中</Button>
      </template>
<!--      处理状态  1=> 未验证 2=>验证中 3=>已验证 4=>验证失败 5=>导入中 6=>已导入-->
      <template slot-scope="item" slot="action">
        <Button class="actbtn" v-if="item.row.handle_status == 1" size="small" type="primary" @click="hanldeData(item.row.id,1)">验证</Button>
        <Button class="actbtn" v-else-if="item.row.handle_status == 2" size="small" type="error" disabled>{{progress['checktxt'+item.row.id]||'验证中..'}}</Button>
        <Button class="actbtn" v-else-if="item.row.handle_status == 3" size="small" type="primary" @click="hanldeData(item.row.id,3)">结算</Button>
        <Button class="actbtn" v-else-if="item.row.handle_status == 4" size="small" type="error" disabled>验证失败</Button>
        <Button class="actbtn" v-else-if="item.row.handle_status == 5" size="small" type="error" disabled>{{progress['importtxt'+item.row.id]||'结算中...'}}</Button>
        <Button class="actbtn" v-else-if="item.row.handle_status == 6" size="small" type="error" disabled>已完成</Button>
        <Button class="actbtn" v-if="item.row.handle_status != 5 && item.row.handle_status != 6" size="small" type="warning" @click="del(item.row.id)">删除</Button>
      </template>
    </Table>
    <div class="footer-m">
      <Page class-name="pagew page-r" :total="total" :current="page" show-total show-elevator show-sizer :page-size="pageSize" :page-size-opts="[15,30,50,100,200]" @on-change="changePage" @on-page-size-change="changePageSize"/>
    </div>
  </div>
</template>
<script>
export default {
  name: "ImportList",
  data(){
    return {
      socket:"",
      columns:[
        {title: '文件名称',slot: 'excel_name',minWidth:130},
        {title: '平台',slot: 'platform_type',minWidth:60,align: 'center'},
        {title: '结算方式',slot: 'settlement_type',minWidth:80,align: 'center'},
        {title: '奖励类型',key: 'reward_type',minWidth:60,align: 'center'},
        {title: '总数量',key: 'totle_line',minWidth:80,align: 'center'},
        {title: '总业绩',slot: 'totle_money',minWidth:120},
        {title: '验证结果',slot: 'success_line',minWidth:140,align: 'center'},
        {title: '验证时间',key: 'check_at',width:140,align: 'center',
          render: (h, params) => {
            return  h('div', params.row.check_at || '--');
          }
        },
        {title: '结算结果',slot: 'settle_success_line',minWidth:140,align: 'center'},
        {title: '创建时间',key: 'create_at',width:140,align: 'center'},
        {title: '结算时间',key: 'import_at',width:140,align: 'center',
          render: (h, params) => {
            return  h('div', params.row.import_at || '--');
          }
        },
        {title: '处理状态',slot: 'handle_status',width:100,align: 'center','fixed':'right'},
        {title: '操作',slot:'action',width:150,align:'center','fixed':'right'},
      ],
      progress:{},
      data:[],
      serData:{handle_status:0,platform_type:0,settlement_type:0,create_at:null},
      page:1,
      pageSize:0,
      total:0,
      loading:false,
      cfg:{platform:{}},
      handle_status:[],
      reConnNum:0
    }
  },
  created() {
    this.init();
  },
  mounted() {
    this.initSocket();
  },
  methods:{
    init:function(){
      let _this = this;
      this.loading = true;
      this.reason = '';
      this.requestApi('/adm/getImportList',{page_size:this.pageSize,page:this.page,ser_data:this.serData}).then(function (res){
        _this.loading = false;
        if(res.status==1){
          _this.total = res.data.page.total;
          _this.pageSize = res.data.page.limit;
          _this.data = res.data.list;
          _this.cfg = res.data.cfg;
          _this.handle_status = res.data.handle_status;
        }
      })
    },
    ser:function(){
      this.page = 1;
      this.init();
    },
    /** 数据处理 **/
    hanldeData:function(id,status){
      this.reConnNum = 0;
      this.send({'id':id,'status':status});
    },
    /** 错误报告 */
    errorReport:function(error_report_file){
      location.href = this.ApiUrl+error_report_file
    },
    /** 删除 */
    del:function(id){
      let _this = this;
      this.$Modal.confirm({
        title: '提示',
        content: '确定要删除吗？',
        onOk: () => {
          this.requestApi('/adm/delImportList', {id:id}).then(function (res) {
            if(res.status == 1){
              _this.$Message.success('删除成功!');
              _this.page = 1;
              _this.init();
            } else
              _this.$Message.error('删除失败!');
          })
        }
      });
    },
    changePage:function (page){
      this.page = page;
      this.init();
    },
    changePageSize:function (pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.init();
    },
    initSocket:function(){
      if(typeof(WebSocket) === "undefined"){
        this.$Notice.warning({
          title: "您的浏览器不支持socket，请更换谷歌等浏览器！"
        });
      }else{
        this.socket = new WebSocket(this.wsUrl)
        this.socket.onopen = this.open;
        this.socket.onerror = this.error;
        this.socket.onmessage = this.getMessage;
        this.socket.onclose=this.close;
      }
    },
    open: function () {
      console.log("open success")
    },
    error: function () {
      console.log('error')
    },
    send: function (params) {
      if(this.socket.readyState == 1){
        params.token = this.getToken();
        this.socket.send(JSON.stringify(params))
      }
      else{
        if(this.reConnNum >= 3){
          this.$Notice.warning({
            title: "服务器连接失败！"
          });
          setTimeout(()=>{
            this.reConnNum = 0;
          },1000)
        }else{
          this.reConnNum = this.reConnNum + 1;
          this.initSocket();
          setTimeout(()=>{
            this.send(params);
          },1000)
        }
      }
    },
    getMessage: function (msg) {
      var data=JSON.parse(msg.data);
      if(data.status==0){ //错误
        this.$Notice.warning({title: data.msg});
        return false;
      }else if(data.status==1 || data.status==2){ //操作中
        this.init();
      }else if(data.status==8){
        this.$set(this.progress,'checktxt'+data.dataid,'验证中:'+data.msg);
      }else if(data.status==9){
        this.$set(this.progress,'importtxt'+data.dataid,'结算中:'+data.msg);
      }
    },
    close: function () {
      console.log('ws closed');
      this.send({"keepLink":"re link"});
    }
  },
  destroyed () {
    this.socket.onclose = this.close
  }
}
</script>
<style lang="less">
.importm{
  .actbtn{
    display: block;
    margin: 0 auto;
    margin-bottom: 5px;
    &:last-child{margin-bottom: 0;}
  }
}
</style>